import { SearchPage } from 'src/constants/searchPages'

export type IPersonalizedPage =
  | SearchPage.Bodega1
  | SearchPage.Bodega3
  | SearchPage.Estacionamiento3
export type IPersonalizedNs =
  | 'page_bodegas_en_renta'
  | 'page_mini_bodega'
  | 'page_estacionamiento_y_pension'
export const PERSONALIZED_PAGES: IPersonalizedPage[] = [
  SearchPage.Bodega1,
  SearchPage.Bodega3,
  SearchPage.Estacionamiento3
]
export const NS_MAP: Record<IPersonalizedPage, IPersonalizedNs> = {
  [SearchPage.Bodega1]: 'page_bodegas_en_renta',
  [SearchPage.Bodega3]: 'page_mini_bodega',
  [SearchPage.Estacionamiento3]: 'page_estacionamiento_y_pension'
}
