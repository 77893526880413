import { IQueryParams } from 'src/types/types'

export enum Competitors {
  UStorage = 'u-storage',
  Multibodegas = 'multibodegas',
  MasEspacio = 'mas-espacio',
  GuardaBox = 'guardabox',
  Spakio = 'spakio'
}

export enum SearchPage {
  Bodega1 = 'bodegas-en-renta',
  Bodega2 = 'self-storage',
  Bodega3 = 'mini-bodegas',
  Estacionamiento1 = 'cajon-de-estacionamiento',
  Estacionamiento2 = 'estacionamiento-cerca-a-mi',
  Estacionamiento3 = 'estacionamiento-y-pension',
  Estacionamiento4 = 'renta-de-estacionamiento'
}

export const SEARCH_PAGE_MAP: {
  [key in SearchPage]: { searchName: string; query: Partial<IQueryParams> }
} = {
  [SearchPage.Bodega1]: {
    searchName: 'bodegas en renta',
    query: { canStoreVehicle: false }
  },
  [SearchPage.Bodega2]: {
    searchName: 'self storage',
    query: { canStoreVehicle: false }
  },
  [SearchPage.Bodega3]: {
    searchName: 'mini bodegas en renta',
    query: { canStoreVehicle: false }
  },
  [SearchPage.Estacionamiento1]: {
    searchName: 'cajon de estacionamiento',
    query: { canStoreVehicle: true }
  },
  [SearchPage.Estacionamiento2]: {
    searchName: 'estacionamiento cerca a mi',
    query: { canStoreVehicle: true }
  },
  [SearchPage.Estacionamiento3]: {
    searchName: 'estacionamiento y pensión',
    query: { canStoreVehicle: true }
  },
  [SearchPage.Estacionamiento4]: {
    searchName: 'renta de estacionamiento',
    query: { canStoreVehicle: true }
  }
}

export enum SearchPageCity {
  Base = '',
  Monterrey = 'monterrey',
  Cdmx = 'cdmx',
  CiudadDeMexico = 'ciudad-de-mexico',
  Guadalajara = 'guadalajara',
  Tijuana = 'tijuana',
  Puebla = 'puebla',
  Queretaro = 'queretaro',
  Leon = 'leon',
  Chihuahua = 'chihuahua',
  Merida = 'merida',
  Cancun = 'cancun',
  Aguascalientes = 'aguascalientes',
  Hermosillo = 'hermosillo',
  PuertoVallarta = 'puerto-vallarta',
  Toluca = 'toluca',
  LosCabos = 'los-cabos',
  Zapopan = 'zapopan'
}

export enum MexicoCityHoods {
  Iztapalapa = 'iztapalapa',
  DeValle = 'del-valle',
  Tlalnepantla = 'tlalnepantla',
  ValleDeChalco = 'valle-de-chalco',
  Azcapotzalco = 'azcapotzalco',
  Naucalpan = 'naucalpan',
  Polanco = 'polanco',

  LasLomas = 'las-lomas',
  Centro = 'centro',
  Condesa = 'condesa',
  Roma = 'roma',
  ColoniaJuarez = 'colonia-juarez',
  Coyoacan = 'coyoacan',
  JardinesDelPedregal = 'jardines-del-pedregal',
  LomasDeChapultepec = 'lomas-de-chapultepec',
  Napoles = 'napoles',
  SanAngel = 'san-angel',
  SantaFe = 'santa-fe',
  Tepito = 'tepito',
  Tlatelolco = 'tlatelolco',
  Anzures = 'anzures',
  Narvarte = 'narvarte',
  Escandon = 'escandon',
  SanRafael = 'san-rafael',
  Doctores = 'doctores',
  Guerrero = 'guerrero',
  Obrera = 'obrera'
}

export enum MonterreyHoods {
  Apodaca = 'apodaca',
  Cumbres = 'cumbres',
  Escobedo = 'general-escobedo',
  Guadalupe = 'guadalupe',
  SantaCatarina = 'santa-catarina',
  SanNicolas = 'san-nicolas',
  SanPedro = 'san-pedro-garza-garcia'
}

export enum GuadalajaraHoods {
  Zapopan = 'zapopan',
  Tlaquepaque = 'san-pedro-tlaquepaque',
  Tonala = 'tonala',
  Tlajomulco = 'tlajomulco-de-zuniga',
  ElSalto = 'el-salto'
}

export type SearchHood = MexicoCityHoods | MonterreyHoods | GuadalajaraHoods

export const SEARCH_PAGE_CITY_MAP: {
  [key in SearchPageCity]: {
    cityName: string
    placeId: string
    hoods?: SearchHood[]
  }
} = {
  [SearchPageCity.Base]: { cityName: '', placeId: '' },
  [SearchPageCity.Monterrey]: {
    cityName: 'Monterrey',
    placeId: 'ChIJ9fg3tDGVYoYRlJjIasrT06M',
    hoods: Object.values(MonterreyHoods)
  },
  [SearchPageCity.Cdmx]: {
    cityName: 'CDMX',
    placeId: 'ChIJB3UJ2yYAzoURQeheJnYQBlQ',
    hoods: Object.values(MexicoCityHoods)
  },
  [SearchPageCity.CiudadDeMexico]: {
    cityName: 'Ciudad de México',
    placeId: 'ChIJB3UJ2yYAzoURQeheJnYQBlQ',
    hoods: Object.values(MexicoCityHoods)
  },
  [SearchPageCity.Guadalajara]: {
    cityName: 'Guadalajara',
    placeId: 'ChIJm9MvtYyxKIQRUFeGvwKTPdY',
    hoods: Object.values(GuadalajaraHoods)
  },
  [SearchPageCity.Tijuana]: {
    cityName: 'Tijuana',
    placeId: 'ChIJ03tYJgI52YARViTmpK9LchQ'
  },
  [SearchPageCity.Puebla]: {
    cityName: 'Puebla',
    placeId: 'ChIJO3q8Xr3Az4URla2U5B1Gpkg'
  },
  [SearchPageCity.Queretaro]: {
    cityName: 'Querétaro',
    placeId: 'ChIJVZJb3I9b04URL4MbVqqUsJc'
  },
  [SearchPageCity.Leon]: {
    cityName: 'León',
    placeId: 'ChIJIefm1v--K4QRJ0OlYeyVbWA'
  },
  [SearchPageCity.Chihuahua]: {
    cityName: 'Chihuahua',
    placeId: 'ChIJM0BIXZ1E6oYRex3dBqen8bc'
  },
  [SearchPageCity.Merida]: {
    cityName: 'Mérida',
    placeId: 'ChIJFw1Fq1xxVo8RCeurFVcV_F0'
  },
  [SearchPageCity.Cancun]: {
    cityName: 'Cancún',
    placeId: 'ChIJ21P2rgUrTI8Ris1fYjy3Ms4'
  },
  [SearchPageCity.Aguascalientes]: {
    cityName: 'Aguascalientes',
    placeId: 'ChIJ-9nkOhTsKYQRIFKcZ3hpAQQ'
  },
  [SearchPageCity.Hermosillo]: {
    cityName: 'Hermosillo',
    placeId: 'ChIJ5a7femiEzoYR-X-I6ZVTPbM'
  },
  [SearchPageCity.PuertoVallarta]: {
    cityName: 'Puerto Vallarta',
    placeId: 'ChIJf8hjUGNFIYQRdFK_hZm_z3k'
  },
  [SearchPageCity.Toluca]: {
    cityName: 'Toluca',
    placeId: 'ChIJuetQKomJzYURxAgCVdVKP60'
  },
  [SearchPageCity.LosCabos]: {
    cityName: 'Los Cabos',
    placeId: 'ChIJoQjlifNKr4YRaLiiwWLfpEs'
  },
  [SearchPageCity.Zapopan]: {
    cityName: 'Zapopan',
    placeId: 'ChIJE0xwqWGuKIQRrALQftSl2K8'
  }
}

export const HOOD_MAP: { [key in SearchHood]: { hoodName: string } } = {
  [MexicoCityHoods.Iztapalapa]: { hoodName: 'Iztapalapa' },
  [MexicoCityHoods.DeValle]: { hoodName: 'Del Valle' },
  [MexicoCityHoods.Tlalnepantla]: { hoodName: 'Tlalnepantla' },
  [MexicoCityHoods.ValleDeChalco]: { hoodName: 'Valle de Chalco' },
  [MexicoCityHoods.Azcapotzalco]: { hoodName: 'Azcapotzalco' },
  [MexicoCityHoods.Naucalpan]: { hoodName: 'Naucalpan' },
  [MexicoCityHoods.Polanco]: { hoodName: 'Polanco' },
  [MexicoCityHoods.LasLomas]: { hoodName: 'Las Lomas' },
  [MexicoCityHoods.Centro]: { hoodName: 'Centro' },
  [MexicoCityHoods.Condesa]: { hoodName: 'Condesa' },
  [MexicoCityHoods.Roma]: { hoodName: 'Roma' },
  [MexicoCityHoods.ColoniaJuarez]: { hoodName: 'Colonia Juarez' },
  [MexicoCityHoods.Coyoacan]: { hoodName: 'Coyoacan' },
  [MexicoCityHoods.JardinesDelPedregal]: { hoodName: 'Jardines del Pedregal' },
  [MexicoCityHoods.LomasDeChapultepec]: { hoodName: 'Lomas de Chapultepec' },
  [MexicoCityHoods.Napoles]: { hoodName: 'Napoles' },
  [MexicoCityHoods.SanAngel]: { hoodName: 'San Angel' },
  [MexicoCityHoods.SantaFe]: { hoodName: 'Santa Fe' },
  [MexicoCityHoods.Tepito]: { hoodName: 'Tepito' },
  [MexicoCityHoods.Tlatelolco]: { hoodName: 'Tlatelolco' },
  [MexicoCityHoods.Anzures]: { hoodName: 'Anzures' },
  [MexicoCityHoods.Narvarte]: { hoodName: 'Narvarte' },
  [MexicoCityHoods.Escandon]: { hoodName: 'Escandon' },
  [MexicoCityHoods.SanRafael]: { hoodName: 'San Rafael' },
  [MexicoCityHoods.Doctores]: { hoodName: 'Doctores' },
  [MexicoCityHoods.Guerrero]: { hoodName: 'Guerrero' },
  [MexicoCityHoods.Obrera]: { hoodName: 'Obrera' },

  [MonterreyHoods.Apodaca]: { hoodName: 'Apodaca' },
  [MonterreyHoods.Cumbres]: { hoodName: 'Cumbres' },
  [MonterreyHoods.Escobedo]: { hoodName: 'General Escobedo' },
  [MonterreyHoods.Guadalupe]: { hoodName: 'Guadalupe' },
  [MonterreyHoods.SantaCatarina]: { hoodName: 'Santa Catarina' },
  [MonterreyHoods.SanNicolas]: { hoodName: 'San Nicolas' },
  [MonterreyHoods.SanPedro]: { hoodName: 'San Pedro Garza Garcia' },

  [GuadalajaraHoods.Zapopan]: { hoodName: 'Zapopan' },
  [GuadalajaraHoods.Tlaquepaque]: { hoodName: 'San Pedro Tlaquepaque' },
  [GuadalajaraHoods.Tonala]: { hoodName: 'Tonala' },
  [GuadalajaraHoods.Tlajomulco]: { hoodName: 'Tlajomulco de Zuniga' },
  [GuadalajaraHoods.ElSalto]: { hoodName: 'El Salto' }
}

export const SEARCH_LINKS_MAP: {
  [key in SearchPage]: { [key in SearchPageCity]: string }
} = Object.values(SearchPage).reduce(
  (memo, page) => {
    return {
      ...memo,
      [page]: Object.values(SearchPageCity).reduce((memo, city) => {
        return {
          ...memo,
          [city]: `/${page}${city ? `/${city}` : ''}`
        }
      }, {})
    }
  },
  {} as { [key in SearchPage]: { [key in SearchPageCity]: string } }
)
