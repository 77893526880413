import {
  SEARCH_LINKS_MAP,
  SearchPage,
  SearchPageCity
} from 'src/constants/searchPages'
import { PERSONALIZED_PAGES } from 'src/screens/PersonalizedLanding/constants'
import { RedirectUrls } from 'src/types/types'

export type IItemProps = {
  href: RedirectUrls | string
  children: string
}

export const SPOTME_LINKS: IItemProps[] = [
  {
    href: RedirectUrls.Help,
    children: 'FOOTER.US'
  },
  {
    href: RedirectUrls.Help,
    children: 'FOOTER.HELP'
  },
  {
    href: RedirectUrls.Help,
    children: 'FOOTER.SAFETY'
  },
  {
    href: RedirectUrls.Refer,
    children: 'FOOTER.HIW'
  },
  {
    href: RedirectUrls.Broker,
    children: 'FOOTER.BROKER'
  },
  {
    href: RedirectUrls.Blog,
    children: 'FOOTER.BLOG'
  }
]

export const STORAGE_LINKS: IItemProps[] = [
  {
    href: `/renta/${PERSONALIZED_PAGES[0]}`,
    children: 'FOOTER.STORAGE_NEAR'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.PERSONAL_STORAGE'
  },
  {
    href: SEARCH_LINKS_MAP[SearchPage.Bodega2][SearchPageCity.Base],
    children: 'FOOTER.STORAGE_UNITS'
  },
  {
    href: `/renta/${PERSONALIZED_PAGES[1]}`,
    children: 'FOOTER.MINI_STORAGE'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.STORAGE_BUSINESS'
  }
]

export const PARKING_LINKS = [
  {
    href: `/renta/${PERSONALIZED_PAGES[2]}`,
    children: 'FOOTER.PARKING_CLOSE'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.GARAGES'
  },
  {
    href: SEARCH_LINKS_MAP[SearchPage.Estacionamiento1][SearchPageCity.Base],
    children: 'FOOTER.MONTHLY_PARKING'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.PENSIONS'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.PARKING_STRUCTURE'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.LONGTERM'
  }
]

export const VEHICLE_LINKS = [
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.VEHICLES'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.BUSES'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.SUV'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.MOTORCYCLES'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.BOATS'
  },
  {
    href: RedirectUrls.Search,
    children: 'FOOTER.RV'
  }
]

export const EXTRA_LINKS = [
  {
    href: RedirectUrls.Directory,
    children: 'FOOTER.DIRECTORY'
  },
  {
    href: RedirectUrls.Negocio,
    children: 'FOOTER.BUSINESS'
  },
  {
    href: RedirectUrls.Help,
    children: 'FOOTER.MEDIA'
  },
  {
    href: RedirectUrls.Calculate,
    children: 'FOOTER.CALCULATE'
  }
]
