import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React, { useState } from 'react'
import { Colors } from '../../constants/theme'
import { RedirectUrls } from '../../types/types'
import BaseButton from '../Buttons/BaseButton'
import FooterLinkSection from './FooterLinkSection'
import SocialMediaIcons from './SocialMediaIcons'
import {
  EXTRA_LINKS,
  PARKING_LINKS,
  SPOTME_LINKS,
  STORAGE_LINKS,
  VEHICLE_LINKS
} from './constants'

const StorageSizeModal = dynamic(
  () => import('../Modals/StorageSizeModal/StorageSizeModal')
)

function Footer() {
  const { t } = useTranslation('footer')
  const [guideOpen, setGuideOpen] = useState(false)

  const joinButtons = (
    <>
      <BaseButton
        fontSize={14}
        size="medium"
        rounded
        variant="outlined"
        customColor={Colors.white}
        href={RedirectUrls.Login}
        className="w-full max-w-[292px] text-nowrap"
      >
        {t('FOOTER.LOGIN')}
      </BaseButton>
      <BaseButton
        fontSize={14}
        size="medium"
        rounded
        whiteBorder
        variant="contained"
        customColor={Colors.white}
        customContrastColor={Colors.black400}
        href={RedirectUrls.Anfitrion}
        className="w-full max-w-[292px] text-nowrap"
      >
        {t('FOOTER.BECOME_HOST')}
      </BaseButton>
    </>
  )
  const fineLinks = (
    <>
      <a
        href={RedirectUrls.TermsAndConditions}
        className="text-sm text-white-500 sm:text-base"
      >
        {t('FOOTER.TERMS')}
      </a>
      <a
        href={RedirectUrls.Disclosures}
        className="mb-1 text-sm text-white-500 sm:text-base"
      >
        {t('FOOTER.PRIVACY')}
      </a>
    </>
  )

  return (
    <>
      <StorageSizeModal
        open={guideOpen}
        handleClose={() => setGuideOpen(false)}
      />

      <footer className="flex bg-black-400 px-5 py-[36px] md:flex md:pb-[60px] md:pt-10 lg:px-7 xl:px-12">
        <div className="w-full">
          <div className="bg-gray-300 footer-mobile-layout">
            <div className="col-span-2 lg:hidden">
              <div className="flex flex-col items-center justify-center gap-7 text-[15px] sm:flex-row sm:justify-between">
                {joinButtons}
              </div>
            </div>
            <div className="md:w-4/4 md:space-mb-1 mt-10 grid w-full grid-cols-1 gap-8 sm:grid-cols-2 md:flex md:gap-4 lg:mt-0">
              <div className="w-full md:w-2/4 ">
                <FooterLinkSection
                  title={t('FOOTER.ABOUT')}
                  firstMap={SPOTME_LINKS}
                />
              </div>
              <div className="w-full text-[15px] sm:text-right md:ml-[-50px] md:w-2/4 md:text-left">
                <FooterLinkSection
                  title={t('FOOTER.STORAGE')}
                  firstMap={STORAGE_LINKS}
                  smTextDirection="right"
                  onClick={() => setGuideOpen(true)}
                  linkTitle={t('FOOTER.SIZE_GUIDE')}
                />
              </div>
              <div className="w-full md:w-2/4 ">
                <FooterLinkSection
                  title={t('FOOTER.PARKING')}
                  firstMap={PARKING_LINKS}
                />
              </div>
              <div className="hidden w-full md:mt-6 md:inline md:w-1/4">
                <FooterLinkSection
                  firstMap={VEHICLE_LINKS}
                  smTextDirection="right"
                />
              </div>
              <div className="w-full sm:text-right md:mt-6 md:hidden md:w-1/4">
                <FooterLinkSection
                  title={t('FOOTER.VEHICLES')}
                  firstMap={VEHICLE_LINKS.slice(1)}
                  smTextDirection="right"
                />
              </div>
              <div className="w-full sm:hidden">
                <FooterLinkSection
                  title={t('FOOTER.EXTRA')}
                  firstMap={EXTRA_LINKS}
                  smTextDirection="right"
                />
              </div>
              <div className="flex flex-col items-center sm:items-start md:hidden">
                <div className="py-2">
                  <SocialMediaIcons
                    primaryColor="text-white-500"
                    secondaryColor="text-black-400"
                    justifyContent="center"
                  />
                </div>
                <div className="flex flex-col items-center gap-4 font-medium sm:items-start">
                  {fineLinks}
                </div>
              </div>
              <div className="hidden w-full text-[15px] sm:block sm:text-right md:w-2/5 md:text-left">
                <FooterLinkSection
                  title={t('FOOTER.EXTRA')}
                  firstMap={EXTRA_LINKS}
                  smTextDirection="right"
                />
              </div>
              <div className="hidden w-full md:w-2/5 lg:inline">
                <div className="space-y-2 md:flex md:flex-col md:space-x-1 md:space-y-2">
                  <div className="flex flex-col space-y-1 pl-6 md:mt-1 md:space-x-0 md:space-y-3">
                    {joinButtons}
                  </div>
                  <div className="mt-4 flex flex-col space-y-2 pt-12 text-right text-[15px] font-medium">
                    {fineLinks}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="flex items-center bg-white-500 px-5 py-[21px] md:flex lg:px-7 lg:py-0 xl:px-12">
        <div className="w-full md:flex md:items-center md:justify-between">
          <div className="w-full md:w-1/2">
            <p className="text-center text-[15px] font-semibold text-black-400 md:text-left">
              Northern Land Domain S.A.P.I. de C.V. Copyright ©{' '}
              <Link href="https://spotme.mx/">SpotMe</Link>
              {` ${new Date().getFullYear()}`}
            </p>
          </div>
          <div className="hidden w-full md:flex md:w-1/2">
            <SocialMediaIcons
              primaryColor="text-black-400"
              secondaryColor={Colors.white500}
              justifyContent="justify-end"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(Footer)
