import styled from '@emotion/styled'

const StyledSocialMediaItem = styled.div`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  padding-top: 2px;
  align-items: center;
  justify-content: center;
`
export const StyledSocialMediaIconsContainer = styled('div')<{
  justifyContent?: string
}>`
  order: 2;
  display: inline-flex;
  gap: 15px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;

  ${({ justifyContent }) =>
    justifyContent !== undefined ? `justify-content: ${justifyContent};` : ''};

  ${({ theme }) => theme.breakpoints.up('md')} {
    order: 3;
  }
`

export type IconVariants = {
  blue: string
  white: string
  default: string
}

export type SocialMediaItem = {
  name: string
  icon: string
  href: string
  size: number
}

export const SOCIAL_MEDIA_ITEMS: SocialMediaItem[] = [
  {
    name: 'facebook',
    icon: 'si-facebook',
    href: 'https://www.facebook.com/SpotMe.Mx/',
    size: 20
  },
  {
    name: 'instagram',
    icon: 'si-instagram',
    href: 'https://instagram.com/spotme.mx?igshid=YmMyMTA2M2Y=',
    size: 20
  },
  {
    name: 'youtube',
    icon: 'si-youtube',
    href: 'https://www.youtube.com/channel/UCdHSatqxAUVKj8QB-Elzmmg/featured',
    size: 20
  },
  {
    name: 'linkedin',
    icon: 'si-linkedin',
    href: 'https://www.linkedin.com/company/spotmemx/',
    size: 20
  },
  {
    name: 'tiktok',
    icon: 'si-tiktok',
    href: 'https://www.tiktok.com/@spotme.mx',
    size: 20
  }
]

interface SocialMediaIconsProps {
  primaryColor: string
  secondaryColor?: string
  justifyContent: string
}

const SocialMediaIcons = ({
  primaryColor,
  secondaryColor,
  justifyContent
}: SocialMediaIconsProps) => {
  return (
    <div
      className={`order-2 inline-flex w-full gap-1 py-3 sm:gap-[18px] md:order-3 md:gap-4 ${justifyContent}`}
    >
      {SOCIAL_MEDIA_ITEMS.map(function (item) {
        return (
          <StyledSocialMediaItem
            key={item.name}
            style={{ backgroundColor: secondaryColor, color: primaryColor }}
          >
            <a href={item.href} rel="noreferrer" target="_blank">
              <i className={`si ${item.icon} ${primaryColor} text-[28px]`} />
            </a>
          </StyledSocialMediaItem>
        )
      })}
    </div>
  )
}

export default SocialMediaIcons
