/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { IItemProps } from './constants'

const StyledLink = ({
  href,
  children
}: IItemProps & { isUpMd?: boolean; isDownMd?: boolean }) => {
  const { t } = useTranslation('footer')
  return (
    <Link href={href} prefetch={false}>
      <div
        className={`font-regular my-2 block list-none text-[15px] leading-6 text-white-500 no-underline hover:cursor-pointer`}
      >
        {t(children as any)}
      </div>
    </Link>
  )
}

type IFooterItemProps = {
  title?: string
  smTextDirection?: string
  linkTitle?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  firstMap: IItemProps[]
  secondMap?: IItemProps[]
}

const FooterLinkSection = ({
  title,
  smTextDirection = 'left',
  linkTitle,
  onClick,
  firstMap,
  secondMap
}: IFooterItemProps) => {
  const DirectionTextxs =
    smTextDirection === 'right' ? 'sm:text-right' : 'sm:text-left'
  return (
    <div>
      <h2
        className={`${DirectionTextxs}
        mb-1 text-[16px] font-bold text-white-500 md:text-left`}
      >
        {title}
      </h2>
      <div className="space-y-2">
        {firstMap.map((item, index) => (
          <StyledLink {...item} key={index} />
        ))}
        {linkTitle && (
          <a
            href="#"
            onClick={onClick}
            className={`font-regular my-1 block list-none text-[15px] leading-6 text-white-500 no-underline hover:cursor-pointer`}
          >
            {linkTitle}
          </a>
        )}
      </div>
      {secondMap && (
        <div className="w-full sm:hidden md:mt-6  md:w-1/4">
          <div className="space-y-2">
            {secondMap.map((item, index) => (
              <StyledLink {...item} key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(FooterLinkSection)
